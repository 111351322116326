import React from "react";
import theme from "theme";
import { Theme, Link, Image, Section, Text, Box, Icon, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { MdBusiness, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Liberty Breeze Soda
			</title>
			<meta name={"description"} content={"Feel The Fresh Caribbean Breeze in Every Sip"} />
			<meta property={"og:description"} content={"Anyone can build a vacation, we build experiences"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/64581775a4bc040020a133cc/images/liberty-breeze-logo.png?v=2023-05-07T21:26:58.194Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/64581775a4bc040020a133cc/images/liberty-breeze-logo.png?v=2023-05-07T21:26:58.194Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/64581775a4bc040020a133cc/images/liberty-breeze-logo.png?v=2023-05-07T21:26:58.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/64581775a4bc040020a133cc/images/liberty-breeze-logo.png?v=2023-05-07T21:26:58.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/64581775a4bc040020a133cc/images/liberty-breeze-logo.png?v=2023-05-07T21:26:58.194Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/64581775a4bc040020a133cc/images/liberty-breeze-logo.png?v=2023-05-07T21:26:58.194Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/64581775a4bc040020a133cc/images/liberty-breeze-logo.png?v=2023-05-07T21:26:58.194Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Section padding="18px 0 18px 0" quarkly-title="Header">
			<Override slot="SectionContent" justify-content="space-around" flex-direction="row" align-items="center" />
			<Image
				max-height="120px"
				src="https://uploads.quarkly.io/64581775a4bc040020a133cc/images/liberty-breeze-logo.png?v=2023-05-07T21:26:58.194Z"
				width="25%"
				height="auto"
				lg-max-width="150px"
				sm-width="50%"
			/>
		</Section>
		<Section padding="60px 0 20px 0" quarkly-title="HeroBlock">
			<Text
				color="--dark"
				lg-font="normal 800 40px/1.2 &quot;Manrope&quot;, sans-serif"
				md-font="normal 800 28px/1.2 &quot;Manrope&quot;, sans-serif"
				margin="0px 0px 0px 0px"
				font="--headline1"
			>
				Liberty Breeze Soda
			</Text>
			<Text margin="0px 0px 0px 0px" font="24px sans-serif">
				Feel The Fresh Caribbean Breeze in Every Sip
			</Text>
		</Section>
		<Section padding="28px 0 74px 0" md-padding="28px 0 28px 0" display="flex">
			<Override
				slot="SectionContent"
				flex-direction="row"
				md-flex-wrap="wrap"
				justify-content="center"
				align-content="center"
				align-items="center"
				width="100%"
				display="flex"
			/>
			<Box min-width="100px" min-height="100px">
				<Image
					object-position="0% 80%"
					lg-max-height="800px"
					md-max-height="600px"
					sm-max-height="500px"
					src="https://uploads.quarkly.io/64581775a4bc040020a133cc/images/soda-examples.png?v=2023-05-07T21:28:03.480Z"
					object-fit="cover"
					margin="32px 0px 0px 0px"
					display="inline-block"
					min-height="400px"
					lg-min-height="300px"
				/>
			</Box>
		</Section>
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://uploads.quarkly.io/64581775a4bc040020a133cc/images/soda-examples.png?v=2023-05-07T21:28:03.480Z)"
		>
			<Override slot="SectionContent" md-align-items="center" />
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap" width="100%">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box sm-display="flex" sm-flex-direction="column" sm-align-items="flex-start">
						<Box
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="500px"
							position="relative"
							sm-display="flex"
							sm-flex-direction="column"
							sm-width="100%"
							sm-max-width="100%"
						>
							<Icon
								position="absolute"
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdBusiness}
							/>
							<Text as="h4" margin="6px 0" font="--base" sm-width="100%">
								Mail us
							</Text>
							<Text
								as="p"
								margin="6px 0"
								font="--headline4"
								sm-width="100%"
								width="500px"
							>
								PO Box 301
								<br />
								Liberty, NY 12754
							</Text>
						</Box>
						<Box
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
							position="relative"
							sm-display="flex"
							sm-flex-direction="column"
							sm-width="100%"
							sm-max-width="100%"
						>
							<Icon
								position="absolute"
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
							/>
							<Text as="h4" margin="6px 0" font="--base" sm-width="100%">
								Email us
							</Text>
							<Text as="p" margin="6px 0" font="--headline4">
								<Link
									href="mailto:hello@company.com"
									text-decoration="none"
									hover-text-decoration="underline"
									color="--light"
									sm-width="100%"
								>
									gmenard1700@gmail.com
								</Link>
							</Text>
						</Box>
						<Box
							padding="0 0 0 64px"
							margin="64px 0 0 0"
							max-width="360px"
							position="relative"
							sm-display="flex"
							sm-flex-direction="column"
							sm-width="100%"
							sm-max-width="100%"
							sm-padding="64px 0 0 0"
						>
							<Icon
								position="absolute"
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdPhone}
								sm-display="block"
							/>
							<Text as="h4" margin="6px 0" font="--base" sm-width="100%">
								Call us
							</Text>
							<Text as="p" margin="6px 0" font="--headline4" sm-width="100%">
								(718) 536-1930
							</Text>
						</Box>
					</Box>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" md-width="100%" sm-padding="0px 0px 0px 0px">
					<LinkBox
						color="#ffffff"
						font="--headline4"
						href="mailto:gmenard1700@gmail.com?subject=%5BLiberty%20Breeze%20Soda%5D%20Contact%20Us"
						target="_blank"
						text-transform="uppercase"
						text-decoration-line="initial"
						background="#0084ff"
						padding="24px 24px 24px 24px"
					>
						<Box font="--headline2">
							Contact Us
						</Box>
						<Box font="--healine4">
							Click Here
						</Box>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});